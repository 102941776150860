<template>
  <div>
    <div>
      <div class="demo-search pt-lg-40">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="search-bar">
              <div class="input-group mb-3">
                <div class="input-group-prepend bg-white">
                  <span><img src="@/assets/images/icons/searchbar.svg" /></span>
                </div>

                <input class="form-control search-demo-input form-input" placeholder="Search by full name"
                  v-model="formPayload.fullName" type="text" name="fullName" />

                <input class="form-control search-demo-input" placeholder="Role" v-model="formPayload.role" type="text"
                  name="role" />
                <input class="form-control search-demo-input" placeholder="Company" v-model="formPayload.company"
                  type="text" name="company" />
                <v-select v-model="formPayload.country" label="name" placeholder="Country" :components="{ Deselect }"
                  :options="countryList" :reduce="(option) => option.alpha2" class="country-select-api"></v-select>
                <div class="find-btn bg-white" v-if="formPayload.country">
                  <button :disabled="isSearchButtonDisable()" @click="checkAPIBody()" class="btn input-group-text ">
                    Search
                  </button>
                </div>
                <div class="find-btn bg-white" v-else>
                  <button v-b-tooltip.hover title="You need to enter a country to use the Search Engine "
                    :disabled="isSearchButtonDisable()" class="btn input-group-text ">
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div class="button-class" v-if="isData">
              <div>
                <span class="select-all-btn mt-1 px-2 mr-2">
                  <input type="checkbox" id="selectAll" name="selectAll" v-model="allSelected"
                    @change="onSelectAllRows()" />
                  <label for="selectAll" class="total-r ml-2">Select All</label>
                </span>

                <button :disabled="isSearchAllEmailDisable()" @click="searchAllEmails()" class="btn btn-sm-primary">
                  Search All Email
                </button>
                <span v-if="this.buttonEnable">
                  <button :disabled="isSearchAllEmailDisable()" @click="searchAllPersonalEmails()"
                    class="btn btn-sm-primary">
                    Search All personal Email
                  </button>
                  <button :disabled="isSearchAllEmailDisable()" @click="searchAllWorkEmails()"
                    class="btn btn-sm-primary">
                    Search All work Email
                  </button>
                </span>
                <button :disabled="isSearchAllEmailDisable()" @click="searchAllPhones()" class="btn btn-sm-primary">
                  Search All Phone
                </button>
                <b-dropdown variant="primary" :text="selectedListName" class="mr-4 small-drop-select"
                  v-model="selectedListName">
                  <b-dropdown-item class="new-list-small" @click="changeSelectedItem('create')">
                    + New List</b-dropdown-item>
                  <b-dropdown-item v-for="(list, i) in listSystems" :key="i" @click="onSelectDropdown(list)">{{
                    list.listName }}
                  </b-dropdown-item>
                </b-dropdown>
                <b-button class="btn btn-sm-primary" :disabled="isSaveAllContactDisable()"
                  @click="saveSalectedContact()">Save Contacts</b-button>
                <router-link :to="{ name: 'userIntegration' }"><b-button class="save-bt">Connect your
                    CRM</b-button></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal ref="createListModel" no-close-on-backdrop centered hide-footer hide-header-close>
        <template #modal-title> Create List </template>
        <div class="d-flex align-items-center text-center">
          <label for="range-1" class="mr-2">Name</label>
          <b-form-input id="range-1" v-model="listName" type="text" placeholder="Enter name"></b-form-input>
        </div>

        <b-button class="mt-3" @click="createList"
          :disabled="listName && listName != '' ? false : true">Create</b-button>
        <b-button variant="danger" class="mt-3 ml-3" @click="closeCreateListModel">Cancel</b-button>
      </b-modal>
    </div>
    <div class="easy-search" v-if="!isData">
      <div class="top-header-search">
        <div class="search-term-data">
          <div class="image-arrow-top text-center mt-4">
            <img src="@/assets/images/icons/arrow-top.svg" />
          </div>
          <div class="term-data-text text-center mt-4">
            <h6>Enter your search term and get data now</h6>
          </div>
        </div>
      </div>
      <div class="data-extention-box mb-4 mt-5 ">
        <div class="data-inner-extention">
          <div class="data-gma-logo mt-2">
            <img src="@/assets/images/icons/data-logo.svg" />
          </div>
          <div class="extention-text-paragraph ml-3">
            <h6>Datagma Chrome Extension</h6>
            <p>
              Research professional contact details from LinkedIn profiles,
              including emails,<br />
              direct dials, and social media profiles.
            </p>
          </div>
          <div class="add-extention-box text-right mt-4">
            <span class="btn btn-primary" @click="navigateToChromeExtention()">Add Chrome Extension</span>
          </div>
        </div>
      </div>
    </div>

    <div class="start-table" v-if="isData">
      <b-table responsive bordered hover :select-mode="'multi'" ref="selectableTable" selectable
        @row-clicked="onRowSelected" @sort-changed="handleSortChange" primary-key="name" :fields="fields" :items="items"
        class="bg-white mw-container-table table-new">
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <input type="checkbox" v-model="data.item.selected" @change="onRowSingeSelected(data)" />
            </div>

            <div v-if="data.item.linkedInUrl" class="linkedin-icon-cls">
              <a :href="data.item.linkedInUrl" target="_blank">{{
                    data.item.name
                  }}</a>
              <img class="d-block mt-1" width="13" src="@/assets/images/icons/linkedin-ico.svg" alt="Linkedin" />
            </div>
            <div v-if="!data.item.linkedInUrl">
              {{ data.item.name }}
            </div>
          </div>
        </template>
        <template #cell(email)="data">
          <div class="text-center">
            <button class="btn save-bt btn-secondary m-auto" @click="callEmailApi(data)"
              v-if="!data.item.showEmail && data.item.name && data.item.company">
              Access Email
            </button>
            <div v-if="data.item.showEmail && data.item.userEmails.length == 0">
              <a :href="'javascript:void(0)'" @click="callFindEmailApi(data)">
                Try Legacy Email</a>
            </div>
            <div class="d-flex flex-column" v-if="data.item.showEmail &&
                    data.item.userEmails &&
                    data.item.userEmails.length > 0
                    ">
              <span class="d-flex justify-content-center" v-for="(emailObj, k) in data.item.userEmails" :key="k">
                {{ emailObj.email }}
                <span class="ml-1" v-if="emailObj.status == 'Valid' ||
                    emailObj.status == 'CatchAllButFoundOnWeb'
                    ">
                  <img src="@/assets/images/icons/valid-ico.svg" />
                </span>
                <span class="ml-1" v-if="emailObj.status == 'CatchAll' || emailObj.status == 'riskey'
                    ">
                  <img src="@/assets/images/icons/invalid-ico.svg" />
                </span>
                <span class="ml-2" v-if="emailObj.status == 'personal'">
                  <img src="@/assets/images/icons/personal.svg" />
                </span>
                <span id="copy-wrapper-email" @click="copyText(emailObj.email)" class="copy-button-email ms-3"
                  title="Copy">
                  <img src="@/assets/images/icons/copy-ico.svg" />
                </span>
                <b-tooltip placement="top" target="copy-wrapper-email">Copy</b-tooltip>
              </span>
            </div>
          </div>
        </template>
        <template #cell(phoneNumber)="data">
          <div class="text-center">
            <b-button class="btn save-bt btn-secondary m-auto" @click="callPhoneApi(data)"
              v-if="!data.item.showPhone && data.item.linkedInUrl">
              Access Phone
            </b-button>
            <div v-if="data.item.showPhone && data.item.userPhones.length > 0">
              <div>
                <img src="@/assets/images/icons/phone-ico.svg" />
                <span class="number-n">
                  {{ data.item.userPhones[0].displayInternational }}</span>
              </div>
              <div>
                <span id="copy-wrapper" @click="
                    copyText(data.item.userPhones[0].displayInternational)
                    " class="copy-button ms-3" title="Copy">
                  <img src="@/assets/images/icons/copy-ico.svg" />
                </span>
                <b-tooltip placement="top" target="copy-wrapper">Copy</b-tooltip>
                <span @click="
                    openWhatsApp(data.item.userPhones[0].displayInternational)
                    " v-if="data.item.userPhones[0] &&
                    data.item.userPhones[0].type &&
                    data.item.userPhones[0].type == 'mobile'
                    " class="copy-button ms-1">
                  <img src="@/assets/images/icons/whatsapp-ico.svg" />
                </span>
                <span v-if="data.item.userPhones && data.item.userPhones.length > 1" class="dropdown-butn-outline">
                  <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                      <img src="@/assets/images/icons/plus-ico.svg" />
                    </template>
                    <span class="icon-drop">
                      <b-dropdown-item href="javascript:void(0)" class="text-dark"
                        v-for="(phoneObj, l) in data.item.userPhones" :key="l">
                        <div class="new-imag-s d-flex justify-content-between">
                          <img src="@/assets/images/icons/phone-ico.svg" />

                          <span class="number-n">
                            {{ phoneObj.displayInternational }}</span>

                          <span @click="copyText(phoneObj.displayInternational)" class="copy-button ms-3" title="Copy">
                            <img src="@/assets/images/icons/copy-ico.svg" />
                          </span>
                          <span @click="openWhatsApp(phoneObj.displayInternational)" v-if="phoneObj &&
                    phoneObj.type &&
                    phoneObj.type == 'mobile'
                    " class="copy-button ms-1">
                            <img src="@/assets/images/icons/whatsapp-ico.svg" />
                          </span>
                        </div>
                      </b-dropdown-item>
                    </span>
                  </b-dropdown>
                </span>
              </div>
            </div>
            <div v-if="data.item.showPhone && data.item.userPhones.length == 0">
              <a :href="'javascript:void(0)'" @click="openSearchPage(data.item)">
                Try Advanced Search</a>
            </div>
          </div>
        </template>
        <template #cell(integration)="data">
          <div v-if="hubspotConnected" class="text-center">
            <button class="border-0 rounded-circle hubspot-icon" @click="saveContactTohubspot(data.item)">
              <img style="width: 24px" :src="require(`@/assets/images/icons/hubspot_logo.svg`)" alt="" />
            </button>
          </div>
        </template>
        <template #cell(save)="data">
          <div class="d-flex align-items-center">
            <b-button class="btn btn-sm-primary" :disabled="selectedListId && selectedListId != '' ? false : true"
              @click="saveContact(data.item)">
              Save
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="pagination-box" v-if="items.length && items.length > 0">
        <span class="prev-btn-nav " v-bind:class="isPaginationDisable('prev') ? 'disabled' : ''"
          @click="pageClick('prev')" :disabled="isPaginationDisable('prev')">
          <span class="triangle-prev"></span>
        </span>
        <span class="number-of-page">
          {{ currentPage == 1 ? 1 : (currentPage - 1) * perPage + 1 }} -
          {{
                    items.length && items.length == 10
                      ? currentPage * perPage
                      : (currentPage - 1) * perPage + items.length
                  }}
          of
          {{
                      items.length && items.length == 10
                        ? 90
                        : (currentPage - 1) * perPage + items.length
                    }}
        </span>
        <span class="next-btn-nav" v-bind:class="isPaginationDisable('next') ? 'disabled' : ''"
          @click="pageClick('next')" :disabled="isPaginationDisable('next')">
          <span class="triangle-next"> </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { loadingService } from "../../common/services/loading.service";
import {
  EASY_SEARCH_ENGINE,
  FIND_USER_EMAIL,
  FIND_PHONENUMBER_OF_USER,
  USER_EMAIL,
  ID_TOKEN_KEY,
  GET_USER_CREDIT,
  SEARCH_CEREBRO_FRANCE_EMAIL,
  GET_USER_LIST_API,
  CREATE_LIST_API,
  SAVE_CONTACTS_LIST_API,
  GET_CONNECTIONS,
  SAVE_CONTACT_TO_HUBSPOT,
  GET_WHATSAPP_PROTO,
} from "../../../store/constant/actions.type";
import { countryList } from "../../../store/constant/intergration.type.js";
import Vue from "vue";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
export default {
  name: "new-search-datatable",
  data() {
    return {
      showResult: false,
      isData: false,
      selectedListName: "Select List",
      selectedListId: null,
      listName: "",
      allSelected: false,
      perPage: 10,
      currentPage: 1,
      countryList: countryList,
      buttonEnable: false,
      Deselect: {
        render: (createElement) => createElement("span", ""),
      },
      formPayload: {
        fullName: null,
        role: null,
        company: null,
        country: null,
        start: 0,
        num: 10,
      },
      loggedUserCountry: null,
      hubspotConnected: false,
      fields: [
        { key: "name", label: "Full Name", sortable: true },
        { key: "jobTitle", label: "Role", sortable: true },
        { key: "company", label: "Company", sortable: true },
        { key: "email", label: "Email Address", sortable: false },
        { key: "phoneNumber", label: "Mobile Phone", sortable: false },
        { key: "integration", label: "Integration", sortable: false },
        { key: "extractedRole", label: "Role", sortable: true },
        { key: "extractedSeniority", label: "Seniority", sortable: true },
        { key: "save", label: "Save", sortable: false },
      ],
      items: [],
      listSystems: [],
      refreshTimer: null,
      logoutDelay: null,
    };
  },
  mounted() {
    this.getUserCardit();
    this.getUserListSystem();
    this.getConnections();
    this.$eventBus.$emit("easySearchMounted");
    this.$eventBus.$emit("easynavbar");
  },
  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
    this.$eventBus.$emit("easySearchMounted");
  },
  methods: {
    getWhatsAppProtocol(val) {
      try {
        this.$store
          .dispatch(GET_WHATSAPP_PROTO)
          .then((response) => {
            this.selectedProtocol = response?.data[0]?.whatapp_protocol;

            if (response?.status) {
              this.$store.dispatch("changeWhatsAppPro", this.selectedProtocol);
              val = val.replace(/ /g, "");
              val = val.replace("+", "");
              val = val.replace(new RegExp("-", "g"), "");

              console.log("selectType", response?.data[0]?.whatapp_protocol);
              if (response?.data[0]?.whatapp_protocol === "mobile") {
                window.open(
                  `https://web.whatsapp.com/send?phone=${val}&text&app_absent=0`,
                  "_blank"
                );
              } else {
                window.open(`whatsapp://send?phone=${val}`, "_blank");
              }
            }
          })
          .catch((err) => {
            if (err != undefined) {
              console.log("error", err);
            }
          });
      } catch (error) {
        console.error("error", error);
      }
    },
    changeSelectedItem(val) {
      if (val === "create") {
        this.$refs["createListModel"].show();
      }
    },
    onSelectDropdown(list) {
      this.selectedListName = list.listName;
      this.selectedListId = list.listId;
    },
    createList() {
      let payload = {
        apiId: localStorage.getItem("$s"),
        user: localStorage.getItem(USER_EMAIL),
        listName: this.listName,
      };
      loadingService.setloader(true);
      if (this.listName !== "") {
        this.$store
          .dispatch(CREATE_LIST_API, payload)
          .then((response) => {
            if (response) {
              this.selectedListName = response.listName;
              this.selectedListId = response.listId;
              this.listSystems.push(response);
              this.$refs["createListModel"].hide();
              this.listName = "";
            }
            loadingService.setloader(false);
          })
          .catch((err) => {
            loadingService.setloader(false);
            console.log(err);
          });
      } else {
        loadingService.setloader(false);
      }
    },
    closeCreateListModel() {
      this.listName = "";
      this.$refs["createListModel"].hide();
    },
    isSearchAllEmailDisable() {
      let a = this.items.some((item) => item.selected == true);
      return !a;
    },
    isSaveAllContactDisable() {
      let a = this.items.some((item) => item.selected == true);
      if (a && this.selectedListId) {
        return false;
      } else {
        return true;
      }
    },
    onRowSelected(item) {
      if (!item.selected) {
        let findIndex = this.items.findIndex((x) => x == item);
        if (findIndex != -1) {
          this.items[findIndex]["selected"] = true;
          const updatedRow = {
            ...this.items[findIndex],
            selected: true,
          };
          this.items.splice(findIndex, 1, updatedRow);
        }
        let selectedItemList = this.items.filter((x) => x.selected == true);
        if (selectedItemList.length == this.items.length) {
          this.allSelected = true;
        }
      } else {
        this.allSelected = false;
        let findIndex = this.items.findIndex((x) => x == item);
        if (findIndex != -1) {
          this.items[findIndex]["selected"] = false;
          const updatedRow = {
            ...this.items[findIndex],
            selected: false,
          };
          this.items.splice(findIndex, 1, updatedRow);
        }
      }
    },
    onRowSingeSelected(item) {
      if (!item.rowSelected) {
        this.items[item.index]["selected"] = true;
        const updatedRow = {
          ...this.items[item.index],
          selected: true,
        };
        this.items.splice(item.index, 1, updatedRow);
        this.$refs.selectableTable.selectRow(item.index);
        let selectedItemList = this.items.filter((x) => x.selected == true);
        if (selectedItemList.length == this.items.length) {
          this.allSelected = true;
        }
      } else {
        this.allSelected = false;
        this.items[item.index]["selected"] = false;
        const updatedRow = {
          ...this.items[item.index],
          selected: false,
        };
        this.items.splice(item.index, 1, updatedRow);
        this.$refs.selectableTable.unselectRow(item.index);
      }
    },
    handleSortChange() {
      this.allSelected = false;
      this.$refs.selectableTable.clearSelected();
      this.items.forEach((element) => {
        element.selected = false;
      });
    },
    onSelectAllRows() {
      if (this.allSelected) {
        this.$refs.selectableTable.selectAllRows();
        this.items.forEach((element) => {
          element.selected = true;
        });
      } else {
        this.$refs.selectableTable.clearSelected();
        this.items.forEach((element) => {
          element.selected = false;
        });
      }
    },
    openSearchPage(item) {
      var handle = window.open("advance-phone-search", "_blank");
      handle.window.parameters = JSON.stringify({
        firstName: item.firstName,
        lastName: item.lastName,
      });
    },
    isSearchButtonDisable() {
      if (
        this.formPayload.country &&
        (this.formPayload.fullName ||
          this.formPayload.role ||
          this.formPayload.company)
      ) {
        return false;
      } else {
        return true;
      }
    },
    commingSoon() {
      this.flashMessage.info({
        title: "",
        message: "Comming Soon",
      });
    },
    // navigateToPricing() {
    //   this.$router.push({ name: "new-pricing" });
    // },
    isPaginationDisable(type) {
      if (type == "prev") {
        if (this.currentPage == 1) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.currentPage == 9) {
          return true;
        } else if (
          this.items.length &&
          this.items.length > 0 &&
          this.items.length < 10
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    pageClick(type) {
      if (type == "prev") {
        this.currentPage = this.currentPage - 1;
      } else {
        this.currentPage = this.currentPage + 1;
      }
      if (this.currentPage == 1) {
        this.formPayload.start = 0;
      } else {
        this.formPayload.start = (this.currentPage - 1) * this.perPage + 1;
      }
      this.callEasySearch();
    },
    checkAPIBody() {
      this.formPayload.start = 0;
      this.formPayload.num = 10;
      this.currentPage = 1;
      if (
        this.formPayload.country &&
        (this.formPayload.fullName ||
          this.formPayload.role ||
          this.formPayload.company)
      ) {
        this.callEasySearch();
      } else {
        // console.log("invalid payload");
      }
    },
    callEasySearch() {
      loadingService.setloader(true);
      this.$store
        .dispatch(EASY_SEARCH_ENGINE, this.formPayload)
        .then((response) => {
          loadingService.setloader(false);
          this.$eventBus.$emit("callCreditMethod");
          this.items = [];
          if (response) {
            if (response.persons && response.persons.length > 0) {
              this.items = response.persons;
            }
            if (this.items.length > 0) {
              this.isData = true;
              this.items.forEach((element) => {
                element.selected = false;
              });
            }
          }
        })
        .catch((err) => {
          if (err && err.data && err.data.code) {
            loadingService.setloader(false);
            if (err.data.message == "can't parse google. g.Items is nil") {
              this.flashMessage.info({
                title: "Message",
                message: "No Results Found.",
              });
            } else {
              this.flashMessage.error({
                title: "Error",
                message: err.data.message
                  ? err.data.message
                  : err.data.description
                    ? err.data.description
                    : "Error Occurs",
              });
            }
          }
        });
    },
    searchAllEmails() {
      for (let i = 0; i < this.items.length; i++) {
        const element = this.items[i];
        if (element.selected) {
          let reqObj = {
            index: i,
            item: { ...element },
          };
          this.callEmailApi(reqObj);
        }
      }
    },
    searchAllPersonalEmails() {
      for (let i = 0; i < this.items.length; i++) {
        const element = this.items[i];
        if (element.selected) {
          let reqObj = {
            index: i,
            item: { ...element },
          };
          this.callPersonalEmailApi(reqObj);
        }
      }
    },
    searchAllWorkEmails() {
      for (let i = 0; i < this.items.length; i++) {
        const element = this.items[i];
        if (element.selected) {
          let reqObj = {
            index: i,
            item: { ...element },
          };
          this.callWorkEmailApi(reqObj);
        }
      }
    },

    saveSalectedContact() {
      let apiId = localStorage.getItem("$s");
      let selectcontact = [];
      for (let i = 0; i < this.items.length; i++) {
        const element = this.items[i];
        const allPhones = [];
        if (element.userPhones && element.userPhones.length > 0) {
          element.userPhones.forEach((ele) => {
            if (ele && ele.displayInternational) {
              allPhones.push(ele.displayInternational);
            }
          });
        }
        if (element.selected) {
          let obj = {
            name: element.name,
            listId: this.selectedListId,
            firstName: element.firstName,
            lastName: element.lastName,
            location: element.location,
            apiId: apiId,
            jobTitle: element.jobTitle,
            company: element.company,
            linkedInUrl: element.linkedInUrl,
            extractedRole: element.extractedRole,
            extractedSeniority: element.extractedSeniority,
            extractedGender: element.extractedGender,
            age: "",
            phones:
              allPhones && allPhones.length > 0 && allPhones ? allPhones : [],
            emails:
              element.userEmails &&
                element.userEmails.length > 0 &&
                element.userEmails
                ? element.userEmails
                : [],
          };
          selectcontact.push(obj);
        }
      }
      if (selectcontact && selectcontact.length > 0) {
        loadingService.setloader(true);
        this.$store
          .dispatch(SAVE_CONTACTS_LIST_API, selectcontact)
          .then((response) => {
            if (response) {
              this.items.forEach((element) => {
                element.selected = false;
              });
              this.$refs.selectableTable.clearSelected();
            }
            loadingService.setloader(false);
            this.flashMessage.success({
              title: "Success",
              message: "Contacts saved",
            });
          })
          .catch((err) => {
            loadingService.setloader(false);
            console.log(err);
          });
      }
    },
    saveContact(element) {
      let apiId = localStorage.getItem("$s");
      let selectcontact = [];
      const allPhones = [];
      if (element.userPhones && element.userPhones.length > 0) {
        element.userPhones.forEach((ele) => {
          if (ele && ele.displayInternational) {
            allPhones.push(ele.displayInternational);
          }
        });
      }

      let obj = {
        name: element.name,
        listId: this.selectedListId,
        firstName: element.firstName,
        lastName: element.lastName,
        location: element.location,
        apiId: apiId,
        jobTitle: element.jobTitle,
        company: element.company,
        linkedInUrl: element.linkedInUrl,
        extractedRole: element.extractedRole,
        extractedSeniority: element.extractedSeniority,
        extractedGender: element.extractedGender,

        age: "",
        phones: allPhones && allPhones.length > 0 && allPhones ? allPhones : [],
        emails:
          element.userEmails &&
            element.userEmails.length > 0 &&
            element.userEmails
            ? element.userEmails
            : [],
      };
      selectcontact.push(obj);
      if (selectcontact && selectcontact.length > 0) {
        loadingService.setloader(true);
        this.$store
          .dispatch(SAVE_CONTACTS_LIST_API, selectcontact)
          .then((response) => {
            if (response) {
              this.items.forEach((element) => {
                element.selected = false;
              });
              this.$refs.selectableTable.clearSelected();
            }
            loadingService.setloader(false);
            this.flashMessage.success({
              title: "Success",
              message: "Contacts saved",
            });
          })
          .catch((err) => {
            loadingService.setloader(false);
            console.log(err);
          });
      }
    },
    searchAllPhones() {
      for (let i = 0; i < this.items.length; i++) {
        const element = this.items[i];
        if (element.selected) {
          let reqObj = {
            index: i,
            item: { ...element },
          };
          this.callPhoneApi(reqObj);
        }
      }
    },
    callEmailApi(data) {
      if (data.item.linkedInUrl && this.formPayload.country == "FR") {
        this.callFindEmailApi(data);
      } else {
        this.callNormalEmailApi(data);
      }
    },
    callFindEmailApi(data) {
      loadingService.setloader(true);
      let linkedInUrl = data.item.linkedInUrl;
      if (linkedInUrl.startsWith("https://")) {
        linkedInUrl = linkedInUrl.split("https://")[1];
      }
      if (linkedInUrl.startsWith("www.")) {
        linkedInUrl = linkedInUrl.split("www.")[1];
      }
      if (countryList && countryList.length > 0) {
        countryList.forEach((element) => {
          if (element && element.alpha2) {
            let alpha2 = element.alpha2.toLowerCase() + ".";
            if (linkedInUrl.startsWith(alpha2)) {
              linkedInUrl = linkedInUrl.split(alpha2)[1];
            }
            let alpha2WithSlace = "/" + element.alpha2.toLowerCase();
            if (linkedInUrl.endsWith(alpha2WithSlace)) {
              linkedInUrl = linkedInUrl.split(alpha2WithSlace)[0];
            }
          }
        });
      }
      var lastChar = linkedInUrl.substr(-1);
      if (lastChar == "/")
        linkedInUrl = linkedInUrl.substring(0, linkedInUrl.lastIndexOf("/"));
      if (linkedInUrl.includes("?")) linkedInUrl = linkedInUrl.split("?")[0];
      if (linkedInUrl.endsWith("/en"))
        linkedInUrl = linkedInUrl.split("/en")[0];
      this.$store
        .dispatch(SEARCH_CEREBRO_FRANCE_EMAIL, linkedInUrl)
        .then((response) => {
          loadingService.setloader(false);
          this.$eventBus.$emit("callCreditMethod");
          if (response) {
            if (response.emails && response.emails.length > 0) {
              let emailArray = [];
              response.emails.forEach((element) => {
                emailArray.push({ email: element.address, status: "personal" });
              });
              const updatedRow = {
                ...this.items[data.index],
                selected: false,
                showEmail: true,
                userEmails: emailArray,
              };
              this.items.splice(data.index, 1, updatedRow);
              // this.callNormalEmailApi(data);
            } else {
              this.callNormalEmailApi(data);
              this.flashMessage.error({
                title: "Error",
                message: "We didn't find any email",
              });
            }
          } else {
            this.callNormalEmailApi(data);
          }
        })
        .catch(() => {
          this.flashMessage.error({
            title: "Error",
            message: "We didn't find any email",
          });
          loadingService.setloader(false);
          this.callNormalEmailApi(data);
        });
    },
    callPersonalEmailApi(data) {
      loadingService.setloader(true);
      let linkedInUrl = data.item.linkedInUrl;
      if (linkedInUrl.startsWith("https://")) {
        linkedInUrl = linkedInUrl.split("https://")[1];
      }
      if (linkedInUrl.startsWith("www.")) {
        linkedInUrl = linkedInUrl.split("www.")[1];
      }
      if (countryList && countryList.length > 0) {
        countryList.forEach((element) => {
          if (element && element.alpha2) {
            let alpha2 = element.alpha2.toLowerCase() + ".";
            if (linkedInUrl.startsWith(alpha2)) {
              linkedInUrl = linkedInUrl.replace(alpha2, "");
            }
            let alpha2WithSlace = "/" + element.alpha2.toLowerCase();
            if (linkedInUrl.endsWith(alpha2WithSlace)) {
              linkedInUrl = linkedInUrl.split(alpha2WithSlace)[0];
            }
          }
        });
      }
      var lastChar = linkedInUrl.substr(-1);
      if (lastChar == "/")
        linkedInUrl = linkedInUrl.substring(0, linkedInUrl.lastIndexOf("/"));
      if (linkedInUrl.includes("?")) linkedInUrl = linkedInUrl.split("?")[0];
      if (linkedInUrl.endsWith("/en"))
        linkedInUrl = linkedInUrl.split("/en")[0];
      this.$store
        .dispatch(SEARCH_CEREBRO_FRANCE_EMAIL, linkedInUrl)
        .then((response) => {
          loadingService.setloader(false);
          this.$eventBus.$emit("callCreditMethod");
          if (response && response.emails && response.emails.length > 0) {
            let emailArray = [];
            response.emails.forEach((element) => {
              emailArray.push({ email: element.address, status: "personal" });
            });
            const updatedRow = {
              ...this.items[data.index],
              selected: false,
              showEmail: true,
              userEmails: emailArray,
            };
            this.items.splice(data.index, 1, updatedRow);
          } else {
            this.flashMessage.error({
              title: "Error",
              message: `No personal email found for ${data.item.name}`,
            });
          }
        })
        .catch(() => {
          loadingService.setloader(false);
          this.flashMessage.error({
            title: "Error",
            message: `No personal email found for ${data.item.name}`,
          });
        });
    },

    callWorkEmailApi(data) {
      loadingService.setloader(true);
      this.$store
        .dispatch(FIND_USER_EMAIL, {
          fullName: data.item.name,
          company: data.item.company,
        })
        .then((response) => {
          this.$eventBus.$emit("callCreditMethod");
          if (response && response.email) {
            let findedEmails = [
              { email: response.email, status: response.status },
            ];
            let oldEmails = this.items[data.index].userEmails;
            if (oldEmails && oldEmails.length > 0) {
              findedEmails = [...findedEmails, ...oldEmails];
            }
            const updatedRow = {
              ...this.items[data.index],
              selected: false,
              showEmail: true,
              userEmails: findedEmails,
            };
            this.items.splice(data.index, 1, updatedRow);
          } else {
            this.flashMessage.error({
              title: "Error",
              message: `No work email found for ${data.item.name}`,
            });
          }
          loadingService.setloader(false);
        })
        .catch(() => {
          loadingService.setloader(false);
          this.flashMessage.error({
            title: "Error",
            message: `No work email found for ${data.item.name}`,
          });
        });
    },

    callNormalEmailApi(data) {
      loadingService.setloader(true);
      this.$store
        .dispatch(FIND_USER_EMAIL, {
          fullName: data.item.name,
          company: data.item.company,
        })
        .then((response) => {
          this.$eventBus.$emit("callCreditMethod");
          if (response && response.email) {
            // console.log("response => ", response);
            let findedEmails = [
              { email: response.email, status: response.status },
            ];
            let oldEmails = this.items[data.index].userEmails;
            if (oldEmails && oldEmails.length > 0) {
              findedEmails = [...findedEmails, ...oldEmails];
            }
            const updatedRow = {
              ...this.items[data.index],
              selected: false,
              showEmail: true,
              userEmails: findedEmails,
            };
            this.items.splice(data.index, 1, updatedRow);
          } else if (response?.status == "CatchAll") {
            let findedEmails = [
              { email: response.mostProbableEmail[0], status: response.status },
            ];
            const updatedRow = {
              ...this.items[data.index],
              selected: false,
              showEmail: true,
              userEmails: findedEmails,
            };
            this.items.splice(data.index, 1, updatedRow);
          } else {
            const updatedRow = {
              ...this.items[data.index],
              selected: false,
              showEmail: true,
              userEmails: [],
            };
            this.items.splice(data.index, 1, updatedRow);
          }
          loadingService.setloader(false);
        })
        .catch((err) => {
          if (
            err.data.message ==
            "rpc error: code = NotFound desc = something went wrong"
          ) {
            loadingService.setloader(false);
            const updatedRow = {
              ...this.items[data.index],
              selected: false,
              showEmail: true,
              userEmails: [],
            };
            this.items.splice(data.index, 1, updatedRow);
          } else if (err && err.data && err.data.code) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.data.message
                ? err.data.message
                : err.data.description
                  ? err.data.description
                  : "Error Occurs",
            });
          }
        });
    },

    getUserListSystem() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_USER_LIST_API, {
          apiKey: localStorage.getItem("$s"),
        })
        .then((response) => {
          if (
            response &&
            response.listSystems &&
            response.listSystems.length > 0
          ) {
            this.listSystems = response.listSystems;
            this.selectedListName = response.listSystems[0].listName;
            this.selectedListId = response.listSystems[0].listId;
          }

          loadingService.setloader(false);
        })
        .catch((err) => {
          if (err && err.data && err.data.code) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.data.message
                ? err.data.message
                : err.data.description
                  ? err.data.description
                  : "Error Occurs",
            });
          }
        });
    },
    callPhoneApi(data) {
      loadingService.setloader(true);
      this.$store
        .dispatch(FIND_PHONENUMBER_OF_USER, {
          linkedInUrl: data.item.linkedInUrl,
        })
        .then((response) => {
          this.$eventBus.$emit("callCreditMethod");
          if (response) {
            let searchRes = [];
            if (response.person && response.person.length > 0) {
              searchRes = [...searchRes, ...response.person];
            }
            if (
              response.person &&
              response.person.constructor.name == "Object"
            ) {
              searchRes = [...searchRes, ...[response.person]];
            }

            if (
              response.possiblePersons &&
              response.possiblePersons.length > 0
            ) {
              searchRes = [...searchRes, ...response.possiblePersons];
            }
            if (
              response.possiblePersons &&
              response.possiblePersons.constructor.name == "Object"
            ) {
              searchRes = [...searchRes, ...[response.possiblePersons]];
            }
            if (searchRes && searchRes.length > 0) {
              let phoneArray = [];
              searchRes.forEach((element) => {
                if (element && element.phones && element.phones.length > 0) {
                  phoneArray = [...phoneArray, ...element.phones];
                }
              });
              if (phoneArray && phoneArray.length > 0) {
                const updatedRow = {
                  ...this.items[data.index],
                  selected: false,
                  showPhone: true,
                  userPhones: phoneArray,
                };
                this.items.splice(data.index, 1, updatedRow);
              }
            } else {
              const updatedRow = {
                ...this.items[data.index],
                selected: false,
                showPhone: true,
                userPhones: [],
              };
              this.items.splice(data.index, 1, updatedRow);
            }
          } else {
            const updatedRow = {
              ...this.items[data.index],
              selected: false,
              showPhone: true,
              userPhones: [],
            };
            this.items.splice(data.index, 1, updatedRow);
          }
          loadingService.setloader(false);
        })
        .catch((err) => {
          if (err.data.message == "not allow from France") {
            loadingService.setloader(false);
            this.flashMessage.info({
              title: "Not found",
              message:
                "Vous n’êtes pas autorisé à faire cette recherche. Si vous pensez que c’est une erreur, contactez-nous.",
            });
          } else if (err && err.data && err.data.code) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.data.message
                ? err.data.message
                : err.data.description
                  ? err.data.description
                  : "Error Occurs",
            });
          }
          const updatedRow = {
            ...this.items[data.index],
            selected: false,
            showPhone: true,
            userPhones: [],
          };
          this.items.splice(data.index, 1, updatedRow);
        });
    },
    close() {
      loadingService.setloader(true);
      this.formPayload;
      this.formPayload.fullName = null;
      this.formPayload.role = null;
      this.formPayload.company = null;
      this.formPayload.country = null;
      this.showResult = !this.showResult;
      this.isData = !this.isData;
      loadingService.setloader(false);
    },
    copyText(val) {
      this.$clipboard(val);
    },
    openWhatsApp(val) {
      this.getWhatsAppProtocol(val);
      // val = val.replace(/ /g, "");
      // val = val.replace("+", "");
      // val = val.replace(new RegExp("-", "g"), "");
      // // window.open(
      // //   `https://web.whatsapp.com/send?phone=${val}&text&app_absent=0`,
      // //   "_blank"
      // // );
      // const whatsappProtocol = this.$store.getters.getWhatsAppPro;
      // console.log("selectType", whatsappProtocol);
      // if (whatsappProtocol === "mobile") {
      //   window.open(
      //     `https://web.whatsapp.com/send?phone=${val}&text&app_absent=0`,
      //     "_blank"
      //   );
      // } else {
      //   window.open(`whatsapp://send?phone=${val}`, "_blank");
      // }
    },
    getUserCardit() {
      let apiId = localStorage.getItem("$s");
      if (
        localStorage.getItem(USER_EMAIL) &&
        localStorage.getItem(USER_EMAIL) !== undefined &&
        localStorage.getItem(ID_TOKEN_KEY) &&
        localStorage.getItem(ID_TOKEN_KEY) !== undefined &&
        apiId &&
        apiId !== undefined
      ) {
        let email = localStorage.getItem(USER_EMAIL);
        let payload = {
          apiId: apiId,
          email: email,
        };
        this.$store
          .dispatch(GET_USER_CREDIT, payload)
          .then((data) => {
            if (data && data.country) {
              this.formPayload.country = data.country;
              this.loggedUserCountry = data.country;
            } else if (this.loggedUserCountry !== "FR") {
              this.buttonEnable = true;
            }
          })
          .catch((err) => {
            this.flashMessage.error({
              title: "Error Message",
              message: err.message,
            });
          });
      }
    },
    navigateToChromeExtention() {
      window
        .open(
          "https://chrome.google.com/webstore/detail/datagma/jcpkgjfbhhglonendnnmicgicebbemjd/",
          "_blank"
        )
        .focus();
    },
    getConnections() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_CONNECTIONS)
        .then((data) => {
          loadingService.setloader(false);
          if (data && data.data && data.data.length > 0) {
            this.hubspotConnected = false;
            let hubIndex = data.data.findIndex((x) => x.key == "hubspot");
            if (hubIndex != -1) {
              this.hubspotConnected = true;
            }
          } else {
            this.hubspotConnected = false;
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          this.hubspotConnected = false;
          if (err && err.data && err.data.message) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error Message",
              message: err.data.message,
            });
          }
        });
    },
    saveContactTohubspot(contact) {
      let contactArray = { inputs: [] };
      let obj = {
        properties: {
          company: contact.company,
          email:
            contact.userEmails && contact.userEmails.length > 0
              ? contact.userEmails[0].email
              : "",
          jobtitle: contact.jobTitle,

          linkedinurl: contact.linkedInUrl,
          phone:
            contact.userPhones &&
              contact.userPhones.length > 0 &&
              contact.userPhones[0].displayInternational
              ? contact.userPhones[0].displayInternational
              : "",
        },
      };
      if (contact.firstName == "" || contact.lastName == "") {
        obj.properties.firstname = contact.name.split(" ")[0];
        obj.properties.lastname = contact.name
          .split(" ")
          .slice(1)
          .join(" ");
      } else if (contact.firstName != "" && contact.lastName != "") {
        obj.properties.firstname = contact.firstName;
        obj.properties.lastname = contact.lastName;
      }
      if (
        (contact.premium != null && contact.premium?.website != "") ||
        contact?.premium?.website != null
      ) {
        obj.properties.website = contact.premium.website;
      } else if (
        contact?.premium == "" ||
        contact?.premium == null ||
        contact?.premium?.website == "" ||
        contact?.premium?.website == null
      ) {
        obj.properties.website = "";
      }
      if (
        (contact.premium != null && contact?.premium?.specialties != "") ||
        contact?.premium?.specialties != null
      ) {
        obj.properties.specialties = contact.premium.specialties;
      } else if (
        contact?.premium == "" ||
        contact?.premium == null ||
        contact?.premium?.specialties == "" ||
        contact?.premium?.specialties == null
      ) {
        obj.properties.specialties = "";
      }
      contactArray.inputs.push(obj);
      loadingService.setloader(true);
      this.$store
        .dispatch(SAVE_CONTACT_TO_HUBSPOT, contactArray)
        .then((response) => {
          console.log(response);
          loadingService.setloader(false);
          this.flashMessage.success({
            title: "Success",
            message: "Contacts saved",
          });
        })
        .catch((err) => {
          // console.log("hubspot save err", err);
          if (err && err.data && err.data.status) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.data.message
                ? err.data.message
                : err.data.description
                  ? err.data.description
                  : "Error Occurs",
            });
          }
        });
    },
  },
};
</script>

<style>
.search-bar {
  position: relative;
}

.search-bar .input-group {
  background: rgb(247 248 250);
  border: 1px solid rgb(223 225 230);
  position: relative;
  border-radius: 8px;
}

.search-bar .input-group-prepend {
  width: 6%;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.input-group-prepend {
  margin-right: -1px;
}

.search-bar .input-group input {
  height: 72px;
  background: #fff;
  color: var(--vs-selected-color);
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.vs--single .vs__selected {
  font-family: "Montserrat";
}

.input-group-text {
  background: rgb(37 137 255);
  color: rgb(255 255 255);
  padding: 20px 40px;
  margin: 13px 20px;
  height: 41px;
  border-radius: 9px;
  width: 81px;
  justify-content: center;
  border: none;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.table-responsive {
  overflow: auto;
}

.input-group input#searchBar1 {
  border-left: none;
}

.copy-button {
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 23px;
}

.copy-button-email {
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 9px;
}

.copy-button:hover,
.copy-button:focus {
  background: transparent;
  outline: none;
}

.copy-button-email:hover,
.copy-button-email:focus {
  background: transparent;
  outline: none;
}

.search-bar input {
  font-weight: normal;
  font-size: 16px;
  border-left: 1px solid rgb(223 225 230);
  border-top: none;
  border-bottom: none;
  color: #a5adba;
  font-family: Montserrat;
  font-weight: 400;
}

.search-bar input.search-demos-input {
  border-right: none;
}

.total-nu {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  padding: 14px 23px 10px;
  align-items: center;
  flex-wrap: wrap;
}

.net-nu {
  display: flex;
  gap: 64px;
  align-items: center;
}

.text-darks {
  color: rgb(37 137 255) !important;
}

.total-r {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat";
  color: rgb(122 134 154);
}

.import-nu h5 {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  color: rgb(37 137 255);
}

.list-start {
  list-style: none;
  display: flex;
  gap: 34px;
}

.list-start li {
  padding: 7px 17px;
  background: rgb(255 255 255);
  border: 1px solid rgb(236 236 236);
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #a5adba;
  font-family: "Montserrat";
}

.main-content {
  background-color: rgb(250 250 250);
}

.relevence-r h5 {
  padding: 7px 17px;
  background: rgb(255 255 255);
  border: 1px solid rgb(236 236 236);
  box-sizing: border-box;
  border-radius: 8px;
  color: #a5adba;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat";
}

.import-nu {
  display: flex;
  gap: 8px;
}

.wrapper .main-content {
  background: rgb(245 249 253);
}

.start-table .table thead th {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  color: #a5adba;
  min-width: 180px;
}

button.btn.save-bt {
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #a5adba;
  margin-right: 52px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-select button {
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #a5adba;
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-select {
  margin-right: 48px;
}

.button-class {
  display: flex;
  justify-content: space-between;
}

.news-select button {
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #a5adba;
  margin-top: 10px;
}

.new-selected button {
  background: rgb(245 249 253);
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: rgb(37 137 255);
}

span.number-n {
  margin-left: 10px;
}

.new-select button:hover {
  background: rgb(37 137 255);
  border: 1px solid rgb(37 137 255);
}

.new-selected button:hover {
  background: rgb(37 137 255);
}

.news-select button:hover {
  background: rgb(37 137 255);
  border: 1px solid rgb(37 137 255);
}

.find-btn {
  border-radius: 8px;
}

.form-control:focus {
  border-color: #dfe1e6;
}

button.show>.btn-secondary.dropdown-toggle {
  color: rgb(255 255 255);
  background-color: rgb(84 91 98);
}

.button-class .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: rgb(255 255 255);
  background-color: rgb(37 137 255);
  border: 1px solid rgb(37 137 255);
}

.new-selected .btn-secondary:not(:disabled):not(.disabled):active,
.new-selected .btn-secondary:not(:disabled):not(.disabled).active,
.new-selected .show>.btn-secondary.dropdown-toggle {
  color: rgb(255 255 255);
  background-color: rgb(37 137 255);
  border-color: rgb(37 137 255);
}

.dropdown-butn-outline button {
  padding: 0;
  margin-left: 18px;
  outline: unset;
}

.dropdown-butn-outline button:focus {
  outline: unset;
  box-shadow: unset;
}

button.btn.save-bt:hover {
  background: rgb(37 137 255);
  border: 1px solid rgb(37 137 255);
  color: #fff;
}

.copy-button:hover img,
.dropdown-butn-outline button:hover img {
  transform: scale(1.1);
}

.copy-button img,
.dropdown-butn-outline button img {
  transition: all 0.5s;
}

.mw-container-table .b-table a,
.mw-container-table .b-table td {
  font-size: 14px;
  line-height: 22px;
}

.mw-container-table .b-table {
  min-width: 1250px;
}

.mw-container-table .b-table th:first-child,
.mw-container-table .b-table td:first-child {
  position: sticky;
  left: 0;
  background: #fff !important;
}

.mw-container-table .b-table tr.table-active td:first-child {
  position: sticky;
  left: 0;
  background: #dadada !important;
}

.mw-container-table .b-table tr:hover td:first-child {
  background-image: linear-gradient(rgb(236 236 236),
      rgb(236 236 236)) !important;
}

.mw-container-table .b-table tr.table-active:hover td:first-child {
  background-image: linear-gradient(#cacaca, #cacaca) !important;
}

.country-select-api {
  flex: 1 1 auto;
  width: 1%;
}

.country-select-api.vs--searchable .vs__dropdown-toggle {
  border: none;
  padding: 0;
  background: rgb(255 255 255);
}

.country-select-api.vs--searchable .vs__dropdown-toggle input {
  margin: 0;
  border: unset;
}

.country-select-api.vs--searchable .vs__dropdown-toggle input::placeholder {
  color: rgb(112, 112, 112);
}

.btn.btn-sm-primary {
  background: rgb(37 137 255);
  border: 1px solid rgb(236 236 236);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: rgb(255 255 255);
  margin-right: 52px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn.btn-sm-primary:hover {
  color: rgb(33 37 41);
}

.triangle-prev {
  display: inline-block;
  border-right: 10px solid rgb(151 152 151);
  border-top: 8px solid rgb(0 0 0 / 0%);
  border-bottom: 8px solid rgb(0 0 0 / 0%);
}

.triangle-next {
  display: inline-block;
  border-left: 10px solid rgb(151 152 151);
  border-top: 8px solid rgb(0 0 0 / 0%);
  border-bottom: 8px solid rgb(0 0 0 / 0%);
}

.triangle-down {
  display: inline-block;
  margin-left: 12px;
  border-left: 6px solid transparent;
  border-top: 7px solid rgb(151 152 151);
  border-right: 6px solid transparent;
}

.pagination-box>span {
  padding: 2px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #b1b7c2;
  font-family: "Montserrat", sans-serif;
}

.pagination-box .disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.pagination-box {
  display: flex;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 13px;
  padding: 5px 10px 5px;
  background: rgb(255 255 255);
  flex-wrap: wrap;
  width: 190px;
  justify-content: space-between;
}

.next-btn-nav {
  border-left: 1px solid rgb(206, 206, 206);
}

.small-custom-select {
  max-width: 170px;
  margin-right: 40px;
  height: 29px;
  font-size: 11px;
  color: rgb(165 173 186);
  border-radius: 8px;
  font-weight: bold;
}

.small-drop-select .btn {
  background: rgb(37 137 255);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: rgb(255 255 255);
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 130px;
}

.new-list-small {
  background: rgb(37 137 255);
}

.new-list-small a {
  color: #fff;
  font-size: 17px;
  font-weight: bold;
}

.dropdown-menu.show {
  padding: 0;
}

/* css add new  */

.data-inner-extention {
  display: flex;
  background: rgb(255 255 255);
  margin: 20px 0px;
  padding: 8px 18px 7px 36px;
  border: 1px solid rgb(223 225 230);
  border-radius: 8px;
}

.extention-text-paragraph h6 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgb(0 49 114);
  margin-top: 10px;
  margin-bottom: 2px;
}

.extention-text-paragraph p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: rgb(0 49 114);
}

.hubspot-icon {
  font-size: 7px;
  height: 40px;
  width: 40px;
  color: rgb(197 9 15);
  background: rgb(238 238 238);
  transition: all 0.3s ease;
}

.add-extention-box {
  width: 63%;
}

/* end css */

@media screen and (max-width: 1600px) {

  button.btn.save-bt,
  .btn.btn-sm-primary {
    margin-right: 10px;
  }
}
</style>
